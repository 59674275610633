import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthApiService } from "../..//api/auth/auth-api.service";
import { Subject, BehaviorSubject } from "rxjs";
import { LoginCreds } from 'src/app/utilities/interface';
import { MessageService } from '../../helper/message.service';
import { LoaderService } from '../../helper/loader.service';
import { ThemeService } from '../../helper/theme.service';
import { THEME_PRIMARY_COLORS, THEME_SECONDARY_COLORS, RULES } from 'src/app/utilities/constants';
import { GoogleAnalyticsService } from '../../helper/google-analytics.service';
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { CommonRulesModalComponent } from "src/app/common/components/common-rules-modal/common-rules-modal.component";

@Injectable()
export class AuthService {

  authState: any = null;
  ipAddress: string = "";
  isAdmin: Subject<boolean> = new Subject<boolean>();
  currentUserSubject: BehaviorSubject<any>;
  public redirectUrl: string = "";

  isLoggingIn: boolean = false;

  constructor(
    private router: Router,
    private authApiService: AuthApiService,
    private messageService: MessageService,

    private googleAnalyticsService: GoogleAnalyticsService,


    private themeService: ThemeService,

    private loaderService: LoaderService,
    private modalService: NgbModal
  ) {
    this.authState = JSON.parse(localStorage.getItem("user"));
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("user"))
    );
  }


  /**
* @method openCommonRulesModal
* @param rules
* @description Open Common Rules Modal
*/
  private openCommonRulesModal() {

    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    const modalRef = this.modalService.open(CommonRulesModalComponent, ngbModalOptions);
    modalRef.componentInstance.rules = RULES;

    modalRef.componentInstance.logout.subscribe($e => this.logout());

  }

  login(usercreds: LoginCreds) {
    this.isLoggingIn = true;
    // this.loaderService.load();
    this.authApiService
      .login(usercreds.login, usercreds.password, usercreds.userIp, usercreds.userType, usercreds.recaptchaToken)
      .subscribe((res) => {
        if (res.type == "success") {
          this.messageService.showToast('Success', "Logged in", 'success')
          this.authState = res.data;

          // //////////////////////////////////////
          // Google Analytics
          // //////////////////////////////////////
          this
            .googleAnalyticsService
            .eventEmitter(this.googleAnalyticsService.LOGIN_EVENT.eventName, this.googleAnalyticsService.LOGIN_EVENT.eventCategory, this.googleAnalyticsService.LOGIN_EVENT.eventLabel, this.googleAnalyticsService.LOGIN_EVENT.eventAction, res.data.userid);

          localStorage.setItem("user", JSON.stringify(res.data));
          this.currentUserSubject.next(res.data);

          // //////////////////////////////////////
          // Opne Dashboard
          // //////////////////////////////////////
          this.openDashBoard();

          // //////////////////////////////////////
          // Common Rules Modal
          // //////////////////////////////////////
          this.openCommonRulesModal();
          this.redirectUrl = "";
        } else {
          this.messageService.showToast('Oops', res.message, 'error')
          this.authState = null;
          this.currentUserSubject.next(null);
        }
        this.isLoggingIn = false;
      }, error => {
        this.messageService.showToast('Error', "We are having some trouble in reaching out to our servers!", 'error')
        this.isLoggingIn = false;
      });

  }

  async openDashBoard() {
    this.router.navigate(["/dashboard/home"]);
  }

  async openChangeOldPassword() {
    this.router.navigate(["/dashboard/change-old-password"]);
  }

  async openLoginPage() {
    this.router.navigate(["auth/login"]);
  }

  async logout() {
    this.loaderService.load()

    this.openLoginPage();
    await localStorage.clear();
    this.authState = null;
    this.currentUserSubject.next(null);

    this.themeService.changeTheme(THEME_PRIMARY_COLORS.DEFAULT, THEME_SECONDARY_COLORS.SECONDARY)


    this.loaderService.clear()

  }

  authUser(): boolean {
    if (localStorage.getItem("user")) {
      this.authState = JSON.parse(localStorage.getItem("user"));
      this.currentUserSubject.next(JSON.parse(localStorage.getItem("user")));
    }
    return this.authState !== null && this.authState !== undefined
      ? true
      : false;
  }

  isProfileAdded(): boolean {
    return this.authUser() && this.authState.is_profile_addded ? true : false;
  }

  /**
 * @method isOldPasswordUpdated
 * @description Cherck if teh users has updated the initial password
 */

  isOldPasswordUpdated(): boolean {
    return this.authUser() && this.authState.passwordtype != 'old' ? true : false;
  }

  /**
   * @name getMDOddsServiceToken
   * @returns string
   */
  getMDOddsServiceToken(): string {
    // if (this.authUser()) {
    //   try {
    //     let mdsToken = JSON.parse(localStorage.getItem("user")).mdsToken;
    //     return mdsToken;
    //   } catch (err) {
    //     this.logout();
    //   }
    // } else {
      return "";
    // }
  }

  getToken() {

    if (this.authUser()) {
      try {
        let token = JSON.parse(localStorage.getItem("user")).token;
        return token;
      } catch (err) {
        this.logout();
      }
    } else {
      return "";
    }
  }

}
