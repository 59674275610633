export const environment = {
  production: true,



  // Kalyan

  appName: "kalyan",

  apiUrl: 'https://api.kalyanexch.com',

  mongodbServiceURL: 'https://mdapi.kalyanexch.com',

  oddsMongodbURL: 'https://odds-api.kalyanexch.com',

  // scoreBetfairAPI: 'https://score-api.kalyanexch.com',

  scoreBetfairAPI :"https://scoredata.365cric.com/#/score5/:eventid",


  redisUrl : "https://oddsapi.247idhub.com/betfair_api",

  firebaseConfig: {
    apiKey: "AIzaSyBhRct3K_0fsEz_EhKD-wlAgqA6Fh-dpAI",
    authDomain: "aaaa-14afe.firebaseapp.com",
    databaseURL: "https://aaaa-14afe.firebaseio.com",
    projectId: "aaaa-14afe",
    storageBucket: "aaaa-14afe.appspot.com",
    messagingSenderId: "333971305520",
    appId: "1:333971305520:web:10cdf1a6fd5407ecf0a9f5",
    measurementId: "G-X98DVQR0KM"
  },

  firebaseConfigAaaCasinoRealtimeDB: {
    apiKey: "AIzaSyBhRct3K_0fsEz_EhKD-wlAgqA6Fh-dpAI",
    authDomain: "aaaa-14afe.firebaseapp.com",
    databaseURL: "https://aaaa-14afe.firebaseio.com",
    projectId: "aaaa-14afe",
    storageBucket: "aaaa-14afe.appspot.com",
    messagingSenderId: "333971305520",
    appId: "1:333971305520:web:10cdf1a6fd5407ecf0a9f5",
    measurementId: "G-X98DVQR0KM"
  },


  defaultUserTypeId: 3,
  placebetTimeout: 25,

  // LIVE TV
  liveTVURL1: "https://timexbet.com/sports/live-tv-6?id=", 


  liveTVURL2: "https://live-tv2.jmk888.com/",
  liveTVURL3: "https://timexbet.com/sports/live-tv-2/",
  // New TVs
  liveTVURL4: "https://timexbet.com/sports/live-tv-4?id=",
  liveTVURL5: "https://timexbet.com/sports/live-tv-8?id=",

  liveTVURL8: "https://100tun.online/web/",

  liveTVURL9: "https://e765432.xyz/static/48efac116d4775729d8ee0cda4a0361df3d3c89b/getdata.php?chid=",

  // liveTVURL8: "https://internal-consumer-apis.jmk888.com/live-tv-5/template?id=",
  statsMatchURL: "https://lmt.route2222.com/#/lmt/",
  // casinoTpOneDayLiveTVURL: "http://casino.kalyanexch.com/tp1day/",
  // casinoTp2020LiveTVURL: "https://oddfancyapi.in/dvideo/teen20",
  // casinoLucky7LiveTVURL: "http://13.126.109.172:4209/lucky7",
  casinoDiamondLiveURL: "https://www.cricketliveline.com/diamond1/",
  casinoDiamondCricExchLiveURL: "https://cricexch.live/video/",
  // LIVE TV

  // GOOGLE SERVICE CONFIGS
  googleAnalyticsTrackingId: "UA-152652959-1",
  recaptchaSecret: "6Lfo3c8ZAAAAANWMUtSftJtj7t_ZGNRFbcBcI9fh",
  // GOOGLE SERVICE CONFIGS

  // CRYPTO CONFIGS
  $Thats$Something: "This!sH@sh$ecREt",
  // CRYPTO CONFIGS

  enableDebuggerDisablingConsole: false,

  // INTERVALS
  defaultEventListReloadInterval: 3000,
  defaultEventOddsReloadInterval: 500,
  defaultUserInfoReloadInterval: 2000,
  // INTERVALS

  // CASINO
  casinoTVURL: "https://feeds.intoday.in/aajtak/api/aajtakhd/master.m3u8",
  casinoLiveVideoLotusURL: "https://streaming.fawk.app/#/sevens/stream",
  casinoLiveVideoLotusURLNew: "https://timexbet.com/sports/live-tv",

  defaulCasinoMyBetsReloadInterval: 20000,
  // CASINO

  defaultSessionTimeout: 21599,  // In secs
  defaultMatchType: 'Match Odds',
};
