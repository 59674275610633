import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SportRepositoryService } from 'src/app/services/repositories/sport/sport-repository.service';
import { UserRepositoryService } from 'src/app/services/repositories/user/user-repository.service';

@Component({
  selector: 'app-exposure-list-modal',
  templateUrl: './exposure-list-modal.component.html',
  styleUrls: ['./exposure-list-modal.component.scss']
})
export class ExposureListModalComponent implements OnInit {

  @Input() name: string = "My Market";

  constructor(
    private activeModal: NgbActiveModal,
    public userRepositoryService: UserRepositoryService
  ) { }

  ngOnInit(): void {

    // Fetch Unsettled User Bets
    this.fetchUserAllUnSettledBets();

  }

  /**
   * @method fetchUserUnSettledBets
   * @param {*}
   * @description Fetch Unsettled User Bets
   */

  fetchUserAllUnSettledBets = () => {
    this._getUserAllUnSettledBets(true, false);
  }


  /**
  * @method resetModal
  * @description Resets the modal
  */
  resetModal = () => {
    this.activeModal.close()
  }


  /********************************************************
   * Helpers
   ********************************************************/

  /**
   * @method _getUserAllUnSettledBets
   * @param {number} sportId
   * @param {boolean} isActive
   * @param {boolean} isDeleted
   * @description UnSettledBets Helper
   */
  _getUserAllUnSettledBets = (isActive: boolean, isDeleted: boolean) => {
    this.userRepositoryService.getUserAllUnSettledBets(isActive, isDeleted);
  }



}
