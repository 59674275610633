import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-common-rules-modal',
  templateUrl: './common-rules-modal.component.html',
  styleUrls: ['./common-rules-modal.component.scss']
})
export class CommonRulesModalComponent implements OnInit {

  @Input() rules: string[];

  @Output() logout = new EventEmitter();

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  /**
* @method resetModal
* @description Resets the modal
*/
  resetModal = () => {
    this.activeModal.close();
  }

  /**
* @method logoutUser
* @description Logout user
*/
  logoutUser = () => {
    this.logout.emit();
    this.resetModal();
  }

}
