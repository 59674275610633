<div class="header">
  <div class="header-top">
    <div class="container-fluid">
      <div class="row justify-content-start align-items-start">
        <div class="col-6 text-left">
          <a (click)="reloadMain()" class="brand">
            <i class="fas fa-home mr-1"></i>
            <span>{{appName | uppercase}}</span>
          </a>
        </div>
        <div class="col-6 text-right">
          <p class="mb-0 bal-expo">
            <i class="fas fa-landmark mr-1"></i>
            <strong>{{userBalance?.balance}}</strong>
          </p>
          <ul class="action-items">
            <li class="pointer" (click)="openExistingExposureListModal()">Exp: {{userBalance?.liability | number:
              '1.0-2'}}</li>
            <li class="dropdown" appToggleMenu #menu="appToggleMenu">
              <span [ngClass]="{'show': menu.toggleMenu}">
                {{userName}}
                <i class="fas fa-chevron-down"></i>
              </span>
              <ul class="dropdown-menu" [ngClass]="{'show': menu.toggleMenu}">
                <li class="dropdown-item">
                  <a class="text-center" style="color: black; font-weight: 700;">
                    <span>Pnl: </span>
                    <span [ngClass]='helperService.getPlofitLossColor(userBalance?.uplineAmount)'>
                      <i class="fas fa-money-bill"></i>
                      {{userBalance?.uplineAmount || 0}}
                    </span>
                  </a>
                </li>
                <hr>
                <li class="dropdown-item">
                  <a (click)="reloadMain()">Home</a>
                </li>
                <li class="dropdown-item">
                  <a [routerLink]="'/dashboard/account-statement'">Account Statement</a>
                </li>
                <li class="dropdown-item">
                  <a [routerLink]="'/dashboard/profit-loss'">Profit Loss Report</a>
                </li>
                <li class="dropdown-item">
                  <a [routerLink]="'/dashboard/bet-history'">Bet History</a>
                </li>
                <li class="dropdown-item">
                  <a [routerLink]="'/dashboard/unsetteled-bet'">Unsetteled Bet</a>
                </li>
                <li class="dropdown-item">
                  <a [routerLink]="'/dashboard/casino-results'">Casino Report History</a>
                </li>
                <li class="dropdown-item">
                  <a [routerLink]="'/dashboard/change-btn-value'">Set Button Values</a>
                </li>
                <!-- <li class="dropdown-item">
                  <a href="javascript:void(0);">Download APK</a>
                </li> -->
                <li class="dropdown-item">
                  <a [routerLink]="'/dashboard/rules'">Rules</a>
                </li>
                <li class="dropdown-item">
                  <a [routerLink]="'/dashboard/change-password'">Change Password</a>
                </li>
                <li class="dropdown-item">
                  <div class="color-pkr-container">
                    <div *ngFor="let themeColor of themeColors" class="color default"
                      [ngClass]="{'active': themeService.primaryColor == themeColor}"
                      [ngStyle]="{'background-color': themeColor}" (click)="changeTheme(themeColor)"></div>
                  </div>
                </li>
                <li>
                  <hr>
                </li>
                <li><a (click)="signOut()">Sign Out</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="header-bottom">
    <div class="container-fluid">
      <div class="marquee ml-5">
        <marquee scrollamount="3">{{userRepositoryService.userHeaderMessage | async}}
        </marquee>
      </div>
    </div>
  </div>
</div>
