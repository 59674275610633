import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../../repositories/auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(
      event => {
        if (event instanceof HttpResponse) {
          if (event.body.responseCode >= 400) {
            // auto logout if responseCode is greater than or equal to 400
            this.authService.logout();
            // location.reload(true);
          }
        }
      }
    )).pipe(catchError(err => {
      // console.log(err.responseCode)
      if (err.responseCode >= 400) {
          // auto logout if status is greater than or equal to 400
          this.authService.logout();
          // location.reload(true);
      }

      // const error = err.error.message || err.statusText;
      return throwError(err);
  }))
  }
}
