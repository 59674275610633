import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private isLoadingSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading = this.isLoadingSource.asObservable()

  constructor() { }

  public load = (): void => {
    this.isLoadingSource.next(true);
  }

  public clear = (): void => {
    this.isLoadingSource.next(false)
  }
}
