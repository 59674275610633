import { Directive, Input, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: '[profitLossColor]'
})
export class ProfitLossColorDirective {

  @Input() profitLossColor: number;

  constructor(private renderer: Renderer2, private el: ElementRef) {}
  ngOnInit() {
    const current = this._getStyles(this.profitLossColor);
    this.renderer.setStyle(
      this.el.nativeElement,
      'color',
      current
    );
  }
  _getStyles(key: number) {
    let color = '';
    if (key < 0) {
      color = 'red';
    }
    else  {
      color = 'green';
    }
    return  color;
  }

}
