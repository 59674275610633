import {
  Directive,
  HostBinding,
  ElementRef,
  HostListener
} from "@angular/core";

@Directive({
  selector: '[appToggleMenu]',
  exportAs: 'appToggleMenu'
})
export class ToggleMenuDirective {

  @HostBinding('class.show') toggleMenu = false;

  constructor() { }

  @HostListener('click', ['$event']) onClick(event) {
    this.toggleMenu = !this.toggleMenu;
  }

}

