import { Injectable } from '@angular/core';
import { UserApiService } from '../../api/user/user-api.service';
import { APIResponse, UserBalance, BetHistory, UserStakes, UnSettledBets, MyMatchBets, AccountStatement, ProfitAndLoss } from 'src/app/utilities/interface';
import { BehaviorSubject, Observable, timer, Subject, Subscription } from 'rxjs';
import { MessageService } from '../../helper/message.service';
import { AuthService } from '../auth/auth.service';
import { takeUntil, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ThemeService } from '../../helper/theme.service';
import { THEME_PRIMARY_COLORS } from 'src/app/utilities/constants';

@Injectable({
  providedIn: 'root'
})
export class UserRepositoryService {

  constructor(private userApiService: UserApiService, private auth: AuthService, private messageService: MessageService, private themeService: ThemeService) { }



  /***************************************
   * User Balance
  **************************************/

  userBalance: UserBalance = null
  isUserBalanceLoading: boolean = false;


  // Kill subject to stop all requests for component
  private killIntervalTrigger: Subject<void> = new Subject();



  /**
   * @method getUserBalance
   * @param {*}
   * @description Returns user's balance
   */
  getUserBalance = () => {
    this.isUserBalanceLoading = true;
    this.userApiService.getBalance().subscribe((res: APIResponse) => {
      if (res.type == 'success') {
        this.userBalance = res.data;
      } else {
        this.userBalance = null;
      }
      this.isUserBalanceLoading = false;
    })
  }


  startGetUserBalanceInterval = () => {
    timer(0, environment.defaultUserInfoReloadInterval).pipe(
      takeUntil(this.killIntervalTrigger),

      switchMap(() => this.userApiService.getBalance()),

    ).subscribe((res) => {
      this.userBalance = res.data;
    })
  }


  removeGetUserBalanceInterval = () => {
    this.killIntervalTrigger.next()
  }


  /***************************************
   * User Bet History
  **************************************/

  //  userBetHistory: BetHistory[] = null
  private userBetHistorySource = new BehaviorSubject<BetHistory[]>(null);
  userBetHistory = this.userBetHistorySource.asObservable()
  isUserBetHistroyLoading: boolean = false;



  /**
   * @method getUserBetHistory
   * @param {string} startDate
   * @param {string} endDate
   * @param {number} sportId
   * @param {boolean} isActive
   * @description Returns user's bet history
   */
  getUserBetHistory = (startDate: string, endDate: string, sportId: number, isActive: boolean) => {
    this.isUserBetHistroyLoading = true;
    // this.userApiService.getBetHistory(startDate, endDate, sportId, isActive).subscribe((res: APIResponse) => {
    //   if (res.type == 'success') {
    //     this.userBetHistorySource.next(res.data)
    //   } else {
    //     this.userBetHistorySource.next(null)
    //   }
    //   this.isUserBetHistroyLoading = false;
    // })

    this.userApiService.getMongodbBetHistory(this.auth.authState.userid, startDate, endDate, sportId, isActive).subscribe((res: APIResponse) => {
      if (res.type == 'success') {
        this.userBetHistorySource.next(res.data)
      } else {
        this.userBetHistorySource.next(null)
      }
      this.isUserBetHistroyLoading = false;
    })
  }


  /***************************************
   * User Profit And Loss
  **************************************/

  private userProfitAndLossSource = new BehaviorSubject<ProfitAndLoss[]>(null);
  userProfitAndLoss = this.userProfitAndLossSource.asObservable()
  isUserProfitAndLossLoading: boolean = false;

  /**
   * @method getUserProfitAndLoss
   * @param {string} startDate
   * @param {string} endDate
   * @description Returns user's profit and loss
   */
  getUserProfitAndLoss = (startDate: string, endDate: string) => {
    this.isUserProfitAndLossLoading = true;
    this.userApiService.getProfitAndLoss(startDate, endDate).subscribe((res: APIResponse) => {
      if (res.type == 'success') {
        this.userProfitAndLossSource.next(res.data)
      } else {
        this.userProfitAndLossSource.next(null)
      }
      this.isUserProfitAndLossLoading = false;
    }, error => {
      this.messageService.showToast('Oops!', "Something went wrong", 'warning');
    })
  }


  /***************************************
   * User Account Statement
  **************************************/

  private userAccountStatementSource = new BehaviorSubject<AccountStatement[]>(null);
  userAccountStatement = this.userAccountStatementSource.asObservable()
  isUserAccountStatementLoading: boolean = false;

  /**
   * @method getUserAccountStatement
   * @param {string} startDate
   * @param {string} endDate
   * @param {string} type
   * @description Returns user's account statement
   */
  getUserAccountStatement = (startDate: string, endDate: string, type: string) => {
    this.isUserAccountStatementLoading = true;
    // this.userApiService.getAccountStatement(startDate, endDate, type).subscribe((res: APIResponse) => {
    //   if (res.type == 'success') {
    //     this.userAccountStatementSource.next(res.data)
    //   } else {
    //     this.userAccountStatementSource.next(null)
    //   }
    //   this.isUserAccountStatementLoading = false;
    // }, error => {
    //   this.messageService.showToast('Oops!', "Something went wrong", 'warning');
    // })

    this.userApiService.getMongodbAccountStatement(this.auth.authState.userid, startDate, endDate, type).subscribe((res: APIResponse) => {
      if (res.type == 'success') {
        this.userAccountStatementSource.next(res.data)
      } else {
        this.userAccountStatementSource.next(null)
      }
      this.isUserAccountStatementLoading = false;
    }, error => {
      this.messageService.showToast('Oops!', "Something went wrong", 'warning');
    })
  }





  /***************************************
   * User UnSettled Bet List
  **************************************/

  private userUnSettledBetsSource = new BehaviorSubject<UnSettledBets[]>(null);
  userUnSettledBets = this.userUnSettledBetsSource.asObservable()
  isUserUnSettledBetsLoading: boolean = false;



  /**
   * @method getUserUnSettledBets
   * @param {number} sportId
   * @param {boolean} isDeleted
   * @param {boolean} isActive
   * @description Returns user's un-settled bets
   */
  getUserUnSettledBets = (sportId: number, isActive: boolean, isDeleted: boolean) => {
    this.isUserUnSettledBetsLoading = true;
    this.userApiService.getUnSettledBets(sportId, isActive, isDeleted).subscribe((res: APIResponse) => {
      if (res.type == 'success') {
        this.userUnSettledBetsSource.next(res.data)
      } else {
        this.userUnSettledBetsSource.next(null)
      }
      this.isUserUnSettledBetsLoading = false;
    })
  }



  /***************************************
 * User All UnSettled Bet List
**************************************/

  private userAllUnSettledBetsSource = new BehaviorSubject<UnSettledBets[]>(null);
  userAllUnSettledBets = this.userAllUnSettledBetsSource.asObservable()
  isUserAllUnSettledBetsLoading: boolean = false;



  /**
   * @method getUserAllUnSettledBets
   * @param {boolean} isDeleted
   * @param {boolean} isActive
   * @description Returns user's  all un-settled bets
   */
  getUserAllUnSettledBets = (isActive: boolean, isDeleted: boolean) => {
    this.isUserAllUnSettledBetsLoading = true;
    this.userApiService.getUnSettledBets(null, isActive, isDeleted).subscribe((res: APIResponse) => {
      if (res.type == 'success') {
        this.userAllUnSettledBetsSource.next(res.data)
      } else {
        this.userAllUnSettledBetsSource.next(null)
      }
      this.isUserAllUnSettledBetsLoading = false;
    })
  }



  /***************************************
   * Change Password
  **************************************/

  private changePasswordStatusSource = new BehaviorSubject<APIResponse>(null);
  changePasswordStatus = this.changePasswordStatusSource.asObservable()
  isPasswordUpdating: boolean = false;


  /**
  * @method changeUserCurrentPasseword
  * @param {string} startDate
  * @param {string} endDate
  * @param {number} sportId
  * @param {boolean} isActive
  * @description Changes User's password
  */
  changeUserCurrentPasseword = (newPassword: string, oldPassword: string) => {
    this.isPasswordUpdating = true;
    this.userApiService.changePassword(newPassword, oldPassword).subscribe((res: APIResponse) => {
      if (res.type == 'success') {
        this.messageService.showToast("Success", res.message, 'success')
        this.auth.logout()
      } else {
        this.messageService.showToast("Error", res.message, 'error')
      }
      this.isPasswordUpdating = false;
    })
  }



  /***************************************
 * Change User Theme Color
**************************************/

  //  private changeUserThemeColorStatusSource = new BehaviorSubject<APIResponse>(null);
  //  changeUserThemeColorStatus = this.changeUserThemeColorStatusSource.asObservable()
  isUpdaingThemeColor: boolean = false;


  /**
  * @method changeUserThemeColor
  * @param {string} color
  * @description Changes User's theme color
  */
  changeUserThemeColor = (color: string) => {
    this.isUpdaingThemeColor = true;
    this.userApiService.changeUserThemColor(color).subscribe((res: APIResponse) => {
      if (res.type == 'success') {
        this.messageService.showToast("Success", res.message, 'success')
        this.getUserThemeColor()
      } else {
        this.messageService.showToast("Error", res.message, 'error')
      }
      this.isUpdaingThemeColor = false;
    })
  }



  /***************************************
 * Get Client Header Message
**************************************/


  private userHeaderMessageSource = new BehaviorSubject<string>(null);
  userHeaderMessage = this.userHeaderMessageSource.asObservable()

  /**
 * @method getUserHeaderMessage
 * @description Returns user header message
 */
  getUserHeaderMessage = () => {
    this.isUserBetHistroyLoading = true;
    this.userApiService.getUserHeaderMessage().subscribe((res: APIResponse) => {
      if (res.type == 'success') {
        this.userHeaderMessageSource.next(res.data.message)
      } else {
        this.userHeaderMessageSource.next(null)
      }
      this.isUserBetHistroyLoading = false;
    })
  }


  /***************************************
 * Get User Theme Color
**************************************/


  //  private userHeaderMessageSource = new BehaviorSubject<string>(null);
  //  userHeaderMessage = this.userHeaderMessageSource.asObservable()

  /**
 * @method getUserThemeColor
 * @description Returns user theme color
 */
  getUserThemeColor = () => {
    this.userApiService.getUserThemeColor().subscribe((res: APIResponse) => {
      if (res.type == 'success') {
        this.themeService.changeTheme(res.data.themecolor)
      } else {
        this.themeService.changeTheme(THEME_PRIMARY_COLORS.DEFAULT)
      }
    })
  }



  /***************************************
   * Fetch User Stacks
  **************************************/

  private userStakesSource = new BehaviorSubject<UserStakes>(null);
  userStakes: Observable<UserStakes> = this.userStakesSource.asObservable()
  isUserStakesLoading: boolean = false;


  /**
  * @method getUserStakes
  * @param {*}
  * @description Fetch User's Stakes
  */
  getUserStakes = (forceUpdate: boolean = false) => {
    const currentVal = this.userStakesSource.getValue()
    if(forceUpdate || !currentVal) { 
      this.isUserStakesLoading = true;
      this.userApiService.getUserStakes().subscribe((res: APIResponse) => {
        if (res.type == 'success') {
          this.userStakesSource.next(res.data)
        } else {
          this.userStakesSource.next(null)
        }
        this.isUserStakesLoading = false;
      }, (error) => {
        this.userStakesSource.next(null)
        this.isUserStakesLoading = false;
      })
    }
  }



  /***************************************
   * Update User Stakes
  **************************************/

  private updateStakesStatusSource = new BehaviorSubject<APIResponse>(null);
  updateStakesStatus = this.updateStakesStatusSource.asObservable()
  isUserStakeUpdating: boolean = false;


  /**
  * @method updateUserStakes
  * @param {any} stakes
  * @description Update User's stakes
  */
  updateUserStakes = (stakes) => {
    this.isUserStakeUpdating = true;
    this.userApiService.updateUserStakes(stakes).subscribe((res: APIResponse) => {
      if (res.type == 'success') {
        this.messageService.showToast("Success", res.message, 'success')
        this.getUserStakes(true)
      } else {
        this.messageService.showToast("Error", res.message, 'error')
      }
      this.isUserStakeUpdating = false;
    })
  }



  /***************************************
   * Active Events Sportwise
   **************************************/


  private myMatchBetsSource = new BehaviorSubject<MyMatchBets[]>([]);
  myMatchBets = this.myMatchBetsSource.asObservable()
  // activeEvents: string[] = []
  isMyMatchBetsLoading: boolean = false;

  betListByMatchSubscription: Subscription = null;

  /**
   * @method getBetListByMatch
   * @param eventId
   * @description Returns the user match bets
   */

  getBetListByMatch = (eventId: string) => {
    let betListByMatchList = {};
    // Clear Existing Subscriptions
    this.clearBetListByMatchSubscriptions()

    if (!eventId) {
      return
    }
    // this.isMyMatchBetsLoading = true;

    // this.betListByMatchSubscription = this.userApiService.getBetListByMatchFS(this.auth.authState.userid, eventId).subscribe(data => {
    //   data.map(e => {
    //     betListByMatchList[e.payload.doc.id] = e.payload.doc.data()
    //     return e.payload.doc.data();
    //   })

    //   this.myMatchBetsSource.next(Object.values(betListByMatchList))
    // });

    // this.isMyMatchBetsLoading = false;


    this.isMyMatchBetsLoading = true;
    this.userApiService.getMongodbBetListByMatch(this.auth.authState.userid, eventId).subscribe((res: APIResponse) => {
      if (res.type == 'success') {
        this.myMatchBetsSource.next(res.data)
      } else {
        this.myMatchBetsSource.next([])
      }
      this.isMyMatchBetsLoading = false;
    })

    this.isMyMatchBetsLoading = false;
  }




  /***************************************
  * Market Betlist User Wise
  **************************************/


  private myMarketBetsSource = new BehaviorSubject<MyMatchBets[]>([]);
  myMarketBets = this.myMarketBetsSource.asObservable()
  isMyMarketBetsLoading: boolean = false;


  /**
 * @method getBetListByMarket
 * @param marketId
 * @description Returns the user market bets
 */

  getBetListByMarket = (marketId: string) => {

    if (!marketId) {
      return
    }

    this.isMyMarketBetsLoading = true;
    this.userApiService.getMongodbBetListByMarket(this.auth.authState.userid, marketId).subscribe((res: APIResponse) => {
      if (res.type == 'success') {
        this.myMarketBetsSource.next(res.data)
      } else {
        this.myMarketBetsSource.next([])
      }
      this.isMyMarketBetsLoading = false;
    })

    this.isMyMarketBetsLoading = false;
  }



  /**
   * @method celarMyMatchBets
   * @param {*}
   * @description Clear user match bets
   */
  celarMyMatchBets = () => {
    this.myMatchBetsSource.next([])
  }

  /***************************************
   * Clear Bet List By Match Suscriptions
  **************************************/

  clearBetListByMatchSubscriptions = () => {
    this.myMatchBetsSource.next([]);
    if (!this.betListByMatchSubscription) {
      return;
    }
    this.betListByMatchSubscription.unsubscribe()
    this.betListByMatchSubscription = null;
  }


}
