import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleMenuDirective } from './toggle-menu/toggle-menu.directive';
import { ProfitLossColorDirective } from './profit-loss-color/profit-loss-color.directive';



@NgModule({
  declarations: [
    ToggleMenuDirective,
    ProfitLossColorDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ToggleMenuDirective,
    ProfitLossColorDirective
  ]
})
export class SharedDirectivesModule { }
