import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DEFAULT_APP_EXCHANGE_NAME, THEME_PRIMARY_COLORS, THEME_SECONDARY_COLORS } from 'src/app/utilities/constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  primaryColor: string;
  secondaryColor: string;

  private appName: string = environment.appName || DEFAULT_APP_EXCHANGE_NAME;

  constructor(@Inject(DOCUMENT) private document) {
    this.changeTheme(this.primaryColor, this.secondaryColor)
  }


  /**
   * @method changeTheme
   * @param primary
   * @param secondary
   * @description Change the Theme of the App.
   */
  changeTheme(primary: string = THEME_PRIMARY_COLORS.DEFAULT, secondary: string = THEME_SECONDARY_COLORS.SECONDARY) {
    this.primaryColor = primary;
    this.secondaryColor = secondary;

    this.document.documentElement.style.setProperty('--data-color-primary', primary);
    this.document.documentElement.style.setProperty('--data-color-secondary', secondary);
  }


  /**
   * @method setAppName
   * @param name
   * @description Set the App Exchange Name
   */
  setAppName = (name?: string) => {
    if(name) {
      this.appName = name;
    } else {
      if(this.document.location.hostname) {
        let hName: string[] = this.document.location.hostname.split('.');
        switch(hName.length) {
          case 1: this.appName = hName[0];
            break;
          case 2: this.appName = hName[0];
            break;
          case 3: this.appName = hName[1];
            break;
          case 4: this.appName = hName[2];
            break;
          default: this.appName = hName[0];
        }
      } else {
        this.appName = DEFAULT_APP_EXCHANGE_NAME
      }
    }
  }


  /**
   * @method getAppName
   * @description Get the App Exchange Name
   */
  getAppName = (): string => {
    return this.appName;
  }

}
