import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserBalance, ActiveSport } from 'src/app/utilities/interface';
import { UserRepositoryService } from 'src/app/services/repositories/user/user-repository.service';
import { ThemeService } from 'src/app/services/helper/theme.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/repositories/auth/auth.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExposureListModalComponent } from 'src/app/modules/dashboard/components/exposure-list-modal/exposure-list-modal.component';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    /*************************************
   * Header data and events
   ************************************/

  @Input() userBalance: UserBalance = null;
  @Input() themeColors: string[] = [];
  @Input() selectedHeaderSport: ActiveSport = null;
  @Input() userName: string = null;
  @Input() appName: string = null;


  @Output() logout = new EventEmitter();

  constructor(
    private router: Router,
    public userRepositoryService: UserRepositoryService,
    public themeService: ThemeService,
    private modalService: NgbModal,
    public helperService: HelperService,
    private authService: AuthService
    ) { }

  ngOnInit(): void {
    this.getUserHeaderMessage();

    this.getUserThemeColor();
  }

  getUserHeaderMessage = () => {
    this.userRepositoryService.getUserHeaderMessage()
  }

  openExistingExposureListModal() {
    const modalRef = this.modalService.open(ExposureListModalComponent);
    modalRef.componentInstance.name = 'Exposure List';
  }


  getUserThemeColor = () => {
    this.userRepositoryService.getUserThemeColor()
  }


  changeTheme = (color: string) => {
    this.userRepositoryService.changeUserThemeColor(color)
  }

  reloadMain = () => {
    this.authService.openDashBoard()
  }

  signOut = () => {
    this.logout.emit()
  }

}
