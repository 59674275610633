import { Injectable, NgZone } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';

// @Injectable({
//   providedIn: 'root'
// })


///////////////////////////////////////////////
// Cloud Firestore
///////////////////////////////////////////////

@Injectable()
export class AngularfirestoreStoreService extends AngularFirestore {}


export function AngularfirestoreStoreFactory(platformId: Object, zone: NgZone) {
  return new AngularFirestore(environment.firebaseConfig, 'firestore', false, null, platformId, zone, null);
}

// export class AngularfirestoreKalyanStoreService extends AngularFirestore {}

// export function AngularfirestoreKalyanStoreFactory(platformId: Object, zone: NgZone) {
//   return new AngularFirestore(environment.firebaseConfigKalyanCasinoRealtimeDB, 'kalyanfirestorestore', false, null, platformId, zone, null);
// }


///////////////////////////////////////////////
// Firebase Realtime
///////////////////////////////////////////////


export class AngularFirebaseAaaRealtimeDBService extends AngularFireDatabase {}

export function AngularFirebaseAaaRealtimeDBFactory(platformId: Object, zone: NgZone) {
  return new AngularFireDatabase(environment.firebaseConfig, 'aaafirebase', null, platformId, zone);

  // Using different Firebase Creds for the realyime db
  // return new AngularFireDatabase(environment.firebaseConfigAaaCasinoRealtimeDB, 'aaafirebase', null, platformId, zone);
}
