import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../repositories/auth/auth.service';

@Injectable()
export class AuthguardService implements CanActivate {

  constructor(private router: Router, private auth: AuthService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.authUser()) {
      return true;
    }
    else {
      this.auth.redirectUrl = state.url;
      this.auth.openLoginPage();
      return false;
      // return true;
    }
  }
}
