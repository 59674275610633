import { FormGroup } from '@angular/forms';

export const  timeSince = (date) => {

    var seconds = Math.floor((+(new Date()) - date) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + " years";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " months";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " hours";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }


export const oneWeekAgo = (date: Date): Date => {
  let sevenDayaTS = (24*60*60*1000) * 7;
  return new Date(date.getTime() - sevenDayaTS)
}

export const isOlderThanCurrent = (date: any): boolean => {
  date = new Date(new Date(date).toUTCString());
  return date.getTime() - new Date().getTime() <= 3600000 * 5.5? true : false; // 6 hrs
}


  // custom validator to check that two fields match
export const MustMatch = (controlName: string, matchingControlName: string) => {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}

export const getFormattedCasinoNameRoute = (name: string) => {
  return name.toLowerCase().replace(' ', '-');
}

