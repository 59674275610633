


export const CRICKET_SPORT_ID: number = 4;
export const ELECTION_SPORT_ID: number = 2378961;
export const TENNIS_SPORT_ID: number = 2;
export const SOCCER_SPORT_ID: number = 1;

export const DEFAULT_APP_EXCHANGE_NAME: string = "exchange";


export const ODDS_TYPE = {
  BACK: "back",
  LAY: "lay"
}


export const RULES: string[] = [
  'If you not accept this agreement do not place any bet.',
  'Cheating bets deleted automatically or manual. No Claim.',
  'Our exchange decision is final and no claim on it.',
  'If any case wrong rate has been given in fancy that particular bets will be cancelled.',
  'We do not accept manual bet.',
  'In case of cheating and betting in unfair rates we will cancel the bet even after settling.',
  'Local fancy are based on Haar - Jeet.',
  'In next men out fancy if player is injured particular fancy will be deleted.',
  'Once our exchange give username and password its your responsibility to change a password.',
  'Incomplete session will be cancelled but complete session will be settled.',
  'In case of match abandoned, cancelled, no result etc. completed sessions will be settled.',
  'In case of a Tie match, only match odds will be cancelled , completed sessions will be continue.',
  'Advance Lambi 1\'st Inning Valid Only.',
  'Due to any technical issue software not work properly at that time we are not responsible for any loss.'

];


// Routes Cintsnts

export const SPORTS_AND_CASINO_ROUTES = {
  SPORTS: 'sports',
  IN_PLAY: 'in-play',
  CASINO_VIRTUAL: 'casino-and-slot-virtual',
  CASINO_LIVE: 'casino-and-slot-live'

}

// Casino Constants

export const CASINO_NAMES = {
  // TEENPATTI
  TEENPATTI: {
    NAME: "teenpatti",
    EVENTS: {
      V_T20: {
        NAME: 'vt20',
        EVENTID: 5041,
        SLUG: 't20',
        IMAGE_SLUG: 'teenpatti',
        FSNAME: 't20'
      },
      T20: {
        NAME: 'dlt20',
        EVENTID: 5047,
        SLUG: 'teen20',
        IMAGE_SLUG: 'teenpatti',
        FSNAME: 't20'
      },
      DIAMOND_ONEDAY: {
        NAME: 'dloneday',
        EVENTID: 5048,
        SLUG: 'Teen',
        IMAGE_SLUG: 'teenpatti',
        FSNAME: 'odtp'
      },
      LOTUS_T20: {
        NAME: 't20',
        EVENTID: 5046,
        SLUG: "T20",
        FSNAME: 't20',
        IMAGE_SLUG: 't20'

      },
      V_OPEN: {
        NAME: 'votp',
        EVENTID: 5042,
        SLUG: 'otp',
        IMAGE_SLUG: 'open',
        FSNAME: 'otp'
      },
    },
    SLUG: "teenpatti",
    ROUTE_SLUG: "teenpatti"
  },


  // LUCKY 7
  LUCKY_7: {
    NAME: "lucky7",
    EVENTS: {
      V_LUCKY_7_A: {
        NAME: 'vlucky7a',
        EVENTID: 5011,
        SLUG: 'lucky7a',
        IMAGE_SLUG: 'lucky7',
        FSNAME: 'lucky7a'
      },
      DIAMOND_LUCKY_7_A: {
        NAME: 'dllucky7a',
        EVENTID: 5017,
        SLUG: 'lucky7eu',
        IMAGE_SLUG: 'lucky7',
        FSNAME: 'lucky7a'
      },
      LOTUS_LUCKY_7_A: {
        NAME: 'lucky7a',
        EVENTID: 5016,
        SLUG: "Dice",
        FSNAME: 'lucky7a',
        IMAGE_SLUG: 'lucky7'

      }
    },
    SLUG: "lucky7",
    ROUTE_SLUG: "lucky7"
  },

  // BOLLYWOOD
  BOLLYWOOD: {
    NAME: "bollywood casino",
    EVENTS: {
      V_AAA: {
        NAME: 'vaaa',
        EVENTID: 5021,
        SLUG: 'aaa',
        FSNAME: 'aaa',
        IMAGE_SLUG: 'aaa'
      },
      DIAMOND_AAA: {
        NAME: 'dlaaa',
        EVENTID: 5026,
        SLUG: 'dlaaa',
        FSNAME: 'aaa',
        IMAGE_SLUG: 'aaa'
      },
      V_BWD: {
        NAME: 'vbwd',
        EVENTID: 5022,
        SLUG: 'bwdtbl',
        FSNAME: 'bwd',
        IMAGE_SLUG: 'bollywood-casino'
      },
      DIAMOND_BWD: {
        NAME: 'dlbwd',
        EVENTID: 5027,
        SLUG: 'btable',
        FSNAME: 'bwd',
        IMAGE_SLUG: 'bollywood-casino'
      },
    },
    ROUTE_SLUG: "bollywood-casino",
    SLUG: "bollywood_casino"
  },

  // POKER
  POKER: {
    NAME: "poker",
    EVENTS: {
      V_POKER_20: {
        NAME: 'vpoker20',
        EVENTID: 5071,
        SLUG: 'poker20',
        FSNAME: 'poker20',
        IMAGE_SLUG: 'poker'
      },
      DIAMOND_POKER_20: {
        NAME: 'dlpoker20',
        EVENTID: 5076,
        SLUG: 'dlpoker20',
        FSNAME: 'poker20',
        IMAGE_SLUG: 'poker'
      },
      V_POKER_1DAY: {
        NAME: 'vpokeroneday',
        EVENTID: 5072,
        SLUG: 'vpokeroneday',
        FSNAME: 'poker1day',
        IMAGE_SLUG: 'poker'
      },
      DIAMOND_POKER_1DAY: {
        NAME: 'dlpokeroneday',
        EVENTID: 5077,
        SLUG: 'dlpokeroneday',
        FSNAME: 'poker1day',
        IMAGE_SLUG: 'poker'
      },
    },
    ROUTE_SLUG: "poker",
    SLUG: "poker"
  },


  // ANDAR BAHAR
  ANDAR_BAHAR: {
    NAME: "andar bahar",
    EVENTS: {
      V_AB20: {
        NAME: 'vab20',
        EVENTID: 5051,
        SLUG: 'ab20',
        FSNAME: 'ab20',
        IMAGE_SLUG: 'andar-bahar'
      },
      DIAMOND_AB20: {
        NAME: 'dlab20',
        EVENTID: 5056,
        SLUG: 'dlab20',
        FSNAME: 'ab20',
        IMAGE_SLUG: 'andar-bahar'
      },
      V_AB2: {
        NAME: 'vab2',
        EVENTID: 5052,
        SLUG: 'ab2',
        FSNAME: 'ab2',
        IMAGE_SLUG: 'andar-bahar2'
      },
    },
    ROUTE_SLUG: "andar-bahar",
    SLUG: "andar_bahar"
  },


  // ROULETTE
  ROULETTE: {
    NAME: "roulette",
    EVENTS: {
      V_ROULETTE: {
        NAME: 'vroulette',
        EVENTID: 5061,
        SLUG: 'roulette',
        FSNAME: 'roulette',
        IMAGE_SLUG: 'roulette'
      },
    },
    ROUTE_SLUG: "roulette",
    SLUG: "roulette"
  },


  // DRAGON TIGER
  DRAGON_TIGER: {
    NAME: "dragon tiger",
    EVENTS: {
      V_DT20: {
        NAME: 'vdt20',
        EVENTID: 5031,
        SLUG: 'dt20',
        IMAGE_SLUG: 'dt',
        FSNAME: 'dt20'
      },
      DIAMOND_DT20: {
        NAME: 'dldt202',
        EVENTID: 5036,
        SLUG: 'dldt202',
        IMAGE_SLUG: 'dt',
        FSNAME: 'dt20'
      },
      V_DTL20: {
        NAME: 'vdtl20',
        EVENTID: 5032,
        SLUG: 'dtl20',
        IMAGE_SLUG: 'dtl',
        FSNAME: 'dtl20'
      },
      DIAMOND_DT1DAY: {
        NAME: 'dldtoneday',
        EVENTID: 5037,
        SLUG: 'dt6',
        IMAGE_SLUG: 'dt',
        FSNAME: 'dtoneday'
      },
    },
    SLUG: "dragon_tiger",
    ROUTE_SLUG: "dragon-tiger"
  },
}


export const CASINO_ROUTE_EVENT_TYPE = {
  VIRTUAL: "virtual",
  LIVE: "live"
}

export const CASINO_FS_BASE_PATHS = {
  WEB_DATA: "webData",
  LOTUS: "lotus",
  BETEX: "betex",
  DIAMOND: "diamond"
}


export const SESSION_MATCH_TYPES = {
  PLAYER: "player",
  SESSION: "session"
}


export const SESSION_BET_TYPES = {
  FANCY: "fancy",
  ODDS: "odd"
}


export const PAGER_LIMITS = [20, 50, 100, 200, 500];


export const FANCY_TABS = {
  FANCY_2_MARKET: "fancy2Market",
  FANCY_2_DIAMOND_MARKET: "Fancy2DiamondMarket",
  FANCY_3_MARKET: "fancy3Market",
  KHADO_SESSION: "khadoSession",
  BALL_SESSION: "ballSession",
  ODD_EVEN_SESSION: "oddEvenSession",
  OTHER_MATCHES: "other_matches"
}


export const FORMATTED_MARKET_NAMES = {
  MATCH_ODDS: "MATCH ODDS",
  COMPLETED_MATCH: "COMPLETED MATCH",
  TIED_MATCH: "TIED MATCH",
  BOOKMAKER: "BOOKMAKER",
  BOOKMAKER_3: "BOOKMAKER 3",
  TOSS: "TOSS",
  WINNER: "WINNER"
}

export const SHORT_MARKET_NAMES = {
  MATCH_ODDS: "ODDS",
  COMPLETED_MATCH: "ODDS",
  TIED_MATCH: "ODDS",
  WINNER: "WINNER",
  FANCY: "F",
  BOOKMAKER: "BM",
  BOOKMAKER_3: "BM3",
  TOSS: "TOSS",
  TEENPATTI: {
    T20: "T20",
    VT20: "VT20",
    VOTP: "VOTP",
    ODTP: "ODTP"
  },
  LUCKY7: {
    LUCKY7A: {
      EVENTS: {
        LUCKY7A: "LUCKY7A"
      },
      NAME: "LUCKY7A",
    },
    VLUCKY7A: {
      EVENTS: {
        VLUCKY7A: "VLUCKY7A"
      },
      NAME: "VLUCKY7A",
    }
  },

  ANDAR_BAHAR: {
    AB20: "AB20",
    DLB20: "DLAB20",
    VAB20: "VAB20",
    AB2: "AB2",
    VAB2: "VAB2"
  },

  ROULETTE: {
    ROULETTE: "RLT",
    VROULETTE: "VRLT"
  },

  BOLLYWOOD_CASINO: {
    VAAA: {
      EVENTS: {
        VAAA: "VAAA"
      },
      NAME: "VAAA",
    },
    DIAMOND_AAA: {
      EVENTS: {
        DLAAA: "DLAAA"
      },
      NAME: "DLAAA",
    },
    VBWD: {
      EVENTS: {
        VBWD: "VBWD"
      },
      NAME: "VBWD",
    },
    DIAMOND_BWD: {
      EVENTS: {
        DLBWD: "DLBWD"
      },
      NAME: "DLBWD",
    }
  },

  POKER: {
    POKER_20: {
      EVENTS: {
        POKER_20: "POKER_20"
      },
      NAME: "POKER_20",
    },
    DIAMOND_POKER_20: {
      EVENTS: {
        DLPOKER20: "DLPOKER20"
      },
      NAME: "DLPOKER20",
    },
    POKER_1DAY: {
      EVENTS: {
        POKER1DAY: "POKER1DAY"
      },
      NAME: "POKER1DAY",
    },
    DIAMOND_POKER_1DAY: {
      EVENTS: {
        DLPOKER1DAY: "DLPOKER20"
      },
      NAME: "DLPOKER20",
    }
  },

  DRAGON_TIGER: {
    DT20: {
      EVENTS: {
        DT20: "DT20"
      },
      NAME: "DT20",
    },
    VDT20: {
      EVENTS: {
        VDT20: "VDT20"
      },
      NAME: "VDT20",
    },
    DIAMOND_DT20: {
      EVENTS: {
        DT20: "DLDT20"
      },
      NAME: "DLDT20",
    },
    DIAMOND_DT20_2: {
      EVENTS: {
        DT20_2: "DLDT20_2"
      },
      NAME: "DLDT20_2",
    },
    DIAMOND_DT_ONE_DAY: {
      EVENTS: {
        DT1DAY: "DLDT1DAY"
      },
      NAME: "DLDT1DAY",
    },
    DTL20: {
      EVENTS: {
        DTL20: "DTL20"
      },
      NAME: "DTL20",
    },
    VDTL20: {
      EVENTS: {
        VDTL20: "VDTL20"
      },
      NAME: "VDTL20",
    }
  }
}

export const MARKET_NAMES = {
  MATCH_ODDS: "Match Odds",
  WINNER: "Winner",
  COMPLETED_MATCH: "Completed Match",
  TIED_MATCH: "Tied Match",
  BOOKMAKER: "Bookmaker",
  BOOKMAKER_2: "Bookmaker2",
  BOOKMAKER_3: "Bookmaker3",
  TOSS: "TOSS",
  FANCY: "Fancy",
  SESSION: {
    FANCY_2_MARKET: "Fancy2Market",
    FANCY_2_DIAMOND_MARKET: "Fancy2DiamondMarket",
    FANCY_3_MARKET: "Fancy3Market",
    KHADO_SESSION: "KhadoSession",
    BALL_SESSION: "BallSession",
    ODD_EVEN_SESSION: "OddEven",
  }
}

export const THEME_PRIMARY_COLORS = {
  DEFAULT: "#0088cc",
  PINK: "#db4095",
  GREEN: "#5eab2b",
  ORANGE: "#e9504a",
  MAROON: "#800000",
  OLIVE: "#808000",
  NAVY: "#000080",
  PURPLE: "#800080"
};

export const MARKET_TYPES = {
  ODDS: "ODDS",
  BOOKMAKER: "BOOKMAKER"
}

export const THEME_SECONDARY_COLORS = {
  SECONDARY: "#2c3e50"
};

export const CASINO_CARD_RANKS = {
  HIGH: "high",
  SEVEN: "seven",
  LOW: "low"
}

export const MARKET_STATUS = {
  SUSPENDED: "SUSPENDED",
  ACTIVE: "ACTIVE",
  OPEN: "OPEN",
  CLOSED: "CLOSED",
  BLANK: "",
}

export const SPECIAL_MATCHES = {
  // ELECTION: {
  //   sportId: 2378961,
  //   matchId: 2102101733,
  //   matchName: "Assembly Election",
  //   date: "2021-03-14T09:25:00.000Z"
  // },
  // PUNJAB_ELECTION_2022: {
  //   sportId: 2378961,
  //   matchId: 1639734509,
  //   matchName: "Punjab Election 2022",
  //   date: "2022-01-24T09:25:00.000Z"
  // },
  // UP_ELECTION_2022: {
  //   sportId: 2378961,
  //   matchId: 1639734858,
  //   matchName: "UP Election 2022",
  //   date: "2022-01-24T09:25:00.000Z"
  // },
  // PSL_2022: {
  //   sportId: 4,
  //   matchId: 28102621,
  //   WINNER: "1.193814712",
  //   matchName: "PSL 2022",
  //   date: "2022-01-27T22:00:00.000Z"
  // },
  IPL2022: {
    sportId: 4,
    matchId: 28127348,
    // MATCH_ODDS: "1.179157300",
    WINNER: "1.194720868",
    // BOOKAMKER: "1.179157300BM",
    matchName: "Indian Premier League",
    date: "2021-04-01T23:00:00.000Z"
  },
  // ICCWC20: {
  //   sportId: 4,
  //   matchId: 28529194,
  //   WINNER: "1.172017987",
  //   matchName: "ICC World Twenty20 2020",
  //   date: "2021-10-11T23:00:00.000Z"
  // },
  // WBBL: {
  //   sportId: 4,
  //   matchId: 28045743,
  //   WINNER: "1.188311121",
  //   matchName: "WBBL",
  //   date: "2021-10-14T23:00:00.000Z"
  // },
  // BBL: {
  //   sportId: 4,
  //   matchId: 27996014,
  //   WINNER: "1.187097151",
  //   matchName: "BBL",
  //   date: "2021-12-05T13:45:00.000Z"
  // },
}


