<div class="modal-header">
  <h4 class="modal-title">Rules</h4>
  <!-- <button type="button" class="close" aria-label="Close" (click)="resetModal()">
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>

<div>
  <ng-container>
    <ng-container *ngIf="rules; else resultNotFound">
      <div class="rules-container">
      <section class="pd-10">
        <b>
          <p *ngFor="let rule of rules; index as i" class="rules-wrapper">
            <span class="mr-10 rule-point">{{i + 1}}. </span>
            <span>{{rule}}</span>
          </p>
        </b>
      </section>
    </div>

    </ng-container>
  </ng-container>

  <!-- Result Not Found Template -->
  <ng-template #resultNotFound>
    <div class="fancy-message col-md-12 mt-1">Not found</div>
  </ng-template>

  <hr>

  <div class="container pd-10">
  <div class="rules-button-container">
    <button  class="btn btn-primary" (click)='logoutUser()'>
      Logout
      <i class=" ml-2 fas fa-lock-open"></i>
    </button>
    <button  class="btn btn-primary" (click)='resetModal()'>
      Accept
      <i class=" ml-2 fas fa-sign-in-alt"></i>
    </button>
  </div>
  </div>

</div>
