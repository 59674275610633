// import { Injectable, Injector } from '@angular/core';
// import { CanActivate, RouterStateSnapshot, Router, ActivatedRouteSnapshot } from '@angular/router';
// import { AuthService } from '../auth/auth.service';
// // import { ToastrService } from 'ngx-toastr';
// import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
// import { Observable } from 'rxjs';

// @Injectable()
// export class TokenInterceptor implements HttpInterceptor {

//   constructor(private injector: Injector, private router: Router, public auth: AuthService) {}

//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     const authReq = req.clone({
//       headers: req.headers.set('Authorization', /* here you fetch your jwt */this.auth.getToken())
//         .append('Access-Control-Allow-Origin', '*')
//     });
//     return next.handle(authReq);
//     // return next.handle(authReq).((event: HttpEvent<any>) => {
//     //   if (event instanceof HttpResponse) {
//     //     // do stuff with response if you want
//     //   }
//     // }, (response: HttpErrorResponse) => { });
//   }
// }


import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../repositories/auth/auth.service';
import { CryptoService } from '../../helper/crypto.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
      private authService: AuthService,
      private cryptoService: CryptoService
      ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authService.authUser();
        if (currentUser && this.authService.getToken()) {
            request = request.clone({
              headers: request.headers.set('Authorization', /* here you fetch your jwt */`Bearer ${this.authService.getToken()}`)
            });

        }

        return next.handle(request);
    }
}
