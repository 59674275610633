import { Component } from '@angular/core';
import { HelperService } from './services/helper/helper.service';
import { AuthService } from './services/repositories/auth/auth.service';
import { MessageService } from './services/helper/message.service';
import { Observable, Subscription, fromEvent, timer, interval } from 'rxjs';

import * as devTools from 'browser-detect-devtools';
import { environment } from 'src/environments/environment';
import { ThemeService } from './services/helper/theme.service';
import { GoogleAnalyticsService } from './services/helper/google-analytics.service';

const devToolsManager = devTools.Manager;

import { NavJS } from '../assets/js/navigatorJs.js';
import { SwUpdate } from '@angular/service-worker';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;
  public subscriptions: Subscription[] = [];


    // Service Worker Update  Latest
    updateChecked = false;
    updateAvailable = false;

    // In your template, use this value to show a loading indicator while we are
    // waiting for updates. (You can also use it to hide the rest of the UI if
    // you want to prevent the old version from being used.)
    get waitingForUpdates() {
      return !this.updateChecked || this.updateAvailable;
    }


  constructor(
    private helper: HelperService,
    private updates: SwUpdate,
    private auth: AuthService,
    private messageService: MessageService,
    private themeService: ThemeService,
    private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.checkForSWUpdate();
    this.checkOnlineOfflineStatus();
    this.getIP()

    this.themeService.setAppName();

    this.googleAnalyticsService.load();

    // this.themeService.changeTheme("red")

  }


  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.

    // //////////////////////////////////////////////
    // Disable Console / Dev Tools
    ////////////////////////////////////////////// //


    // You can also receive events developer tools are opened, closed, and get position.
    // IMPORTANT : Use this function only when in release mode.
    // If you call this method, the console.log is reset regardless of the alwaysConsoleClear function called.

    if (environment.enableDebuggerDisablingConsole) {
      devToolsManager.startDevToolMonitoring((isOpened, orientation) => {
        // orientation : 'horizontal' / 'vertical' / 'separated-window'

        if (isOpened && (['horizontal', 'vertical', 'separated-window'].indexOf(orientation) != -1) && !NavJS.isIOS()) {

          if (this.auth.authState) {
            this.auth.logout()
            location.reload()
          }

        }
      });


      // // Always want to clear console log of the browser's developer tools, use `alwaysConsoleClear` API.
      devToolsManager.alwaysConsoleClear(true); // enable function


      // Pause the browser session when Browser's developer tools are open, use `freezeWhenDevToolsOpened` API.
      devToolsManager.freezeWhenDevToolsOpened(true); // enable function
    }
  }

    // Service Worker Update  Latest
    checkForSWUpdate = async () => {
      this.updates.available.subscribe(() => {
        // Keep the loading indicator active while we reload the page
        this.updateAvailable = true;
        window.location.reload();
      });
      if (this.updates.isEnabled) {
        // This promise will return when the update check is completed,
        // and if an update is available, it will also wait for the update
        // to be downloaded (at which point it calls our callback above and
        // we just need to reload the page to apply it).
        await this.updates.checkForUpdate();
      } else {
        console.log('Service worker updates are disabled.');
      }
      // The update check is done (or service workers are disabled), now
      // we can take the loading indicator down (unless we need to apply an
      // update, but in that case, we have already set this.updateAvailable
      // to true by this point, which keeps the loading indicator active).
      this.updateChecked = true;
    }


  getIP = async () => {
    let ipAdd = await this.helper.getIPAddress()
    this.auth.ipAddress = ipAdd.ip
  }

  checkOnlineOfflineStatus = () => {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(event => {
      this.messageService.clearAllToasts()
      this.messageService.showToast("Success", 'Back Online!', "success")

    }));
    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.messageService.showToast("Error", 'You seem to be offline!', "error", { disableTimeOut: true, tapToDismiss: false })
    }));
  }

  onRightClk = () => {
    return false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
