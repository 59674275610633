import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {


  /*
  * Google Analytics Constants
  */
 LOGIN_EVENT = {
  eventName: "User Login",
  eventCategory: "Auth",
  eventLabel: "Login",
  eventAction: "click",
  eventValue: "User Logged in successfully",
}

  constructor() { }

  load = () => {
    gtag('config', environment.googleAnalyticsTrackingId, { 'send_page_view': false });
  }

  public eventEmitter(eventName: string, eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: string = null ){

    gtag('event', eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue
    });
  }
}
