import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class AuthApiService {
  apiURL: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  public login(login: string, pass: string, userIp: string, userType: string, recaptchaToken: string) {
    return this.httpClient.post<{ type: string; message: string, data: any }>(
      `${this.apiURL}/auth/login`,
      { login: login, password: pass, userIp, userType, recaptchaToken }
    );
  }

  public register(
    login: string,
    pass: string,
    first_name: string,
    last_name: string
  ) {
    try {
      return this.httpClient.post<{ type: string; message: string; data: any }>(
        `${this.apiURL}/user/create`,
        { email: login, password: pass, first_name, last_name }
      );
    } catch (err) {
      throw err;
    }
  }

  public updateProfile(
    userCreds
  ) {
    try {
      return this.httpClient.put<{ type: string; message: string; data: any }>(
        `${this.apiURL}/dashboard/profile/edit`,
        userCreds
      );
    } catch (err) {
      throw err;
    }
  }

  public userNotifications() {
    try {
      return this.httpClient.get<{ type: string; message: string; data: any }>(
        `${this.apiURL}/dashboard/user/notifications`
      );
    } catch (err) {
      throw err;
    }
  }

  public getUserProfileData() {
    try {
      return this.httpClient.get<{ type: string; message: string; data: any }>(
        `${this.apiURL}/user/update`
      );
    } catch (err) {
      throw err;
    }
  }

  public forgotPassword(email: string) {
    try {
      return this.httpClient.put<{ type: string; message: string; data: any }>(
        `${this.apiURL}/user/password_reset?email=${email}`,
        null
      );
    } catch (err) {
      throw err;
    }
  }

}
