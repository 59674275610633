import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { APIResponse } from 'src/app/utilities/interface';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  private apiURL: string = environment.apiUrl;

  private mongodbServiceURL: string = environment.mongodbServiceURL;

  constructor(private httpClient: HttpClient, private firestore: AngularFirestore) { }

  /**
   * This function returns the user balance and liability
   * @method getBalance
   * @returns { APIResponse } User Balance
   */

  public getBalance = () => {
    return this.httpClient.get<APIResponse>(
      `${this.apiURL}/user/get-balance`
    );
  };


  /**
* This function returns the cleint header message
* @method getUserHeaderMessage
* @returns { APIResponse } Herader Message
*/

  public getUserHeaderMessage = () => {
    return this.httpClient.get<APIResponse>(
      `${this.apiURL}/user/get-client-header-message`
    );
  };


  /**
* This function returns the user theme color
* @method getUserThemeColor
* @returns { APIResponse } User theme color
*/

  public getUserThemeColor = () => {
    return this.httpClient.get<APIResponse>(
      `${this.apiURL}/user/get-user-theme-color`
    );
  };


  /**
   * @method getBetHistory
   * @param startDate
   * @param endDate
   * @param sportId
   * @param isActive
   * @returns { APIResponse } User Bet History
   * @description This function returns the user bet history
   */

  public getBetHistory = (startDate: string, endDate: string, sportId: number, isActive: boolean = false) => {
    return this.httpClient.get<APIResponse>(
      `${this.apiURL}/user/get-bet-history?startDate=${startDate}&endDate=${endDate}&sportId=${sportId}&isActive=${isActive}`
    );
  };


  /**
* @method getMongodbBetHistory
* @param {string} userId
* @param {string} startDate
* @param {string} sportId
* @param {string} endDate
* @param {string} isActive
* @returns { APIResponse } User Bets
* @description This function returns the user's bet history
*/

  public getMongodbBetHistory = (userId: string, startDate: string, endDate: string, sportId: number, isActive: boolean = false) => {
    return this.httpClient.get<APIResponse>(
      `${this.mongodbServiceURL}/bets/get-bet-history?userId=${userId}&startDate=${startDate}&endDate=${endDate}&sportId=${sportId}`
    );
  };



  /**
   * This function returns the account statement
   * @method getAccountStatement
   * @returns { APIResponse } User Account Statement
   */

  public getAccountStatement = (startDate: string, endDate: string, type: string) => {
    return this.httpClient.get<APIResponse>(
      `${this.apiURL}/user/get-account-statement?startDate=${startDate}&endDate=${endDate}&type=${type}`
    );
  };


  /**
  * @method getMongodbAccountStatement
  * @param {string} userId
  * @param {string} startDate
  * @param {string} type
  * @param {string} endDate
  * @returns { APIResponse } User Account Statement
  * @description This function returns the user's account statement
  */

  public getMongodbAccountStatement = (userId: string, startDate: string, endDate: string, type: string) => {
    return this.httpClient.get<APIResponse>(
      `${this.mongodbServiceURL}/user/get-account-statement?userId=${userId}&startDate=${startDate}&endDate=${endDate}&type=${type}`
    );
  };



  /**
   * This function returns the profit and loss
   * @method getProfitAndLoss
   * @returns { APIResponse } User Profit And Loss
   */

  public getProfitAndLoss = (startDate: string, endDate: string) => {
    return this.httpClient.get<APIResponse>(
      `${this.apiURL}/user/get-profit-and-loss?startDate=${startDate}&endDate=${endDate}`
    );
  };


  /**
   * This function returns the user unsettled bets
   * @method getUnSettledBets
   * @returns { APIResponse } User Un Settled Bets
   */

  public getUnSettledBets = (sportId: number, isActive: boolean = true, isDeleted: boolean = false) => {
    let queryURL = `?isActive=${isActive}&isDeleted=${isDeleted}`;
    if (sportId) {
      queryURL += `&sportId=${sportId}`
    }
    return this.httpClient.get<APIResponse>(
      `${this.apiURL}/user/get-unsettled-bets${queryURL}`
    );
  };



  /**
   * This function updates the user's password
   * @method changePassword
   * @returns { APIResponse } Password Updation Conformation
   */

  public changePassword = (newPassword: string, oldPassword: string) => {
    return this.httpClient.patch<APIResponse>(
      `${this.apiURL}/user/change-password`,
      { newPassword, oldPassword }
    );
  };


  /**
 * This function updates the user's theme color
 * @method changeUserThemColor
 * @returns { APIResponse } User Theme Update Confirmation
 */

  public changeUserThemColor = (color: string) => {
    return this.httpClient.patch<APIResponse>(
      `${this.apiURL}/user/change-user-theme-color`,
      { color }
    );
  };


  /**
  * This function fetches the user's stakes
  * @method getUserStakes
  * @returns { APIResponse } User Stakes
  */

  public getUserStakes = () => {
    return this.httpClient.get<APIResponse>(
      `${this.apiURL}/user/get-user-stacks`
    );
  };

  /**
   * This function fetches the user's bet list by match
   * @method getBetListByMatchFS
   * @returns { APIResponse } Bet List By Match
   */
  public getBetListByMatchFS(userId: string, eventId: string) {
    return this.firestore.collection("t_betlist", ref => ref.where("userid", '==', userId).where("matchid", '==', eventId).where("isactive", '==', true)).snapshotChanges()
  }


  /**
   * This function updates the user's stakes
   * @method updateUserStakes
   * @returns { APIResponse } Stakes Updation Conformation
   */

  public updateUserStakes = (stakes: any) => {
    return this.httpClient.patch<APIResponse>(
      `${this.apiURL}/user/update-stack`,
      stakes
    );
  };


  /**
   * @method getBetListByMatch
   * @deprecated
   * @param {string} eventId
   * @returns { APIResponse } Match Bets
   * @description This function returns the user match bets
   */

  public getBetListByMatch = (eventId: string) => {
    return this.httpClient.get<APIResponse>(
      `${this.apiURL}/market/get-betlist-by-match/${eventId}`
    );
  };


  /**
* @method getMongodbBetListByMarket
* @param {string} userId
* @param {string} marketId
* @returns { APIResponse } Market Bets
* @description This function returns the user Market bets
*/

  public getMongodbBetListByMarket = (userId: string, marketId: string) => {
    return this.httpClient.get<APIResponse>(
      `${this.mongodbServiceURL}/bets/get-betlist-by-market?userId=${userId}&marketId=${marketId}`
    );
  };

  /**
 * @method getMongodbBetListByMatch
 * @param {string} userId
 * @param {string} eventId
 * @returns { APIResponse } Match Bets
 * @description This function returns the user match bets
 */

  public getMongodbBetListByMatch = (userId: string, eventId: string) => {
    return this.httpClient.get<APIResponse>(
      `${this.mongodbServiceURL}/bets/get-betlist-by-match?userId=${userId}&eventId=${eventId}`
    );
  };
}
