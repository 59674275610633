import { Injectable } from '@angular/core';

import AES from 'crypto-js/aes';
import { enc } from 'crypto-js';
import { environment } from 'src/environments/environment';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor(private themeService: ThemeService) { }


  getTSHash = (): string => {
    let ts = new Date().getTime();
    let _text = `${this.themeService.getAppName()}$_$${ts}`;
    return this.encrypt(_text);
  }


  private encrypt = (message: string): string => {
    // Encrypt
    const cipher_text = AES.encrypt(message, environment.$Thats$Something).toString();
    return cipher_text;
  }

  private decrypt = (hash: string): string => {
    // Decrypt
    var bytes = AES.decrypt(hash, environment.$Thats$Something);
    var originalText = bytes.toString(enc.Utf8);
    return '';
  }
}
