import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { RouterModule } from '@angular/router';
import { AuthService } from './services/repositories/auth/auth.service';
import { AuthguardService } from './services/authgaurd/authguard.service';
import { httpInterceptorProviders } from './services/interceptors';
import { SharedComponentsModule } from './common/components/shared-components.module';
import { AngularFirebaseAaaRealtimeDBFactory, AngularFirebaseAaaRealtimeDBService, AngularfirestoreStoreFactory, AngularfirestoreStoreService } from './services/angular-firestore-extension/angular-firestore-extension.service';
import { ServiceWorkerModule } from '@angular/service-worker';



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,

    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,

    CommonModule,
    SharedComponentsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

  ],
  providers: [
    AuthService,
    AuthguardService,
    httpInterceptorProviders,
    { provide: AngularfirestoreStoreService, deps: [PLATFORM_ID, NgZone], useFactory: AngularfirestoreStoreFactory },
    { provide: AngularFirebaseAaaRealtimeDBService, deps: [PLATFORM_ID, NgZone], useFactory: AngularFirebaseAaaRealtimeDBFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
