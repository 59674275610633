import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CASINO_CARD_RANKS,
  CRICKET_SPORT_ID,
  FORMATTED_MARKET_NAMES,
  MARKET_STATUS,
  ODDS_TYPE,
  SHORT_MARKET_NAMES,
  SOCCER_SPORT_ID,
  SPECIAL_MATCHES,
  TENNIS_SPORT_ID,
} from 'src/app/utilities/constants';
import { ProfitLoss, UserBetProfitAndLoss } from 'src/app/utilities/interface';
import { isOlderThanCurrent } from 'src/app/utilities/helper';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(private http: HttpClient) {}

  getScoreColour = (score): String => {
    if (score <= 1) {
      score *= 100;
    }
    if (score < 33) return 'error';
    else if (score < 66) return 'warning';
    else return 'success';
  };

  getBallDetailsClass = (ball: string): String => {
    switch (ball) {
      case '4':
        return 'four';
      case '6':
        return 'six';
      case 'W':
      case 'ww':
        return 'wicket';
      case 'wb':
      case 'w':
        return 'wide';
      case 'nb':
      case 'n':
        return 'no-ball';
      default:
        if (ball.includes('n')) {
          return 'no-ball';
        } else if (ball.includes('ww')) {
          return 'wicket';
        } else if (ball.includes('w')) {
          return 'wide';
        } else {
          return '';
        }
    }
  };

  getCricketMatchStatUrl = (
    eID: string,
    height: number = 250,
    width: number | string = 'auto'
  ) => {
    return `https://videoplayer.betfair.com/GetPlayer.do?tr=500&eID=${eID}&contentType=viz&contentOnly=true&statsToggle=show&width=${width}&height=${height}`;
  };

  getSoccerMatchStatUrl = (
    eID: string,
    height: number = 250,
    width: number | string = window.innerWidth - 5 || 334
  ) => {
    return `https://videoplayer.betfair.com/GetPlayer.do?tr=1&eID=${eID}&width=${width}&height=${height}&allowPopup=true&contentType=viz&statsToggle=hide&contentOnly=true`;
  };

  getTennisMatchStatUrl = (
    eID: string,
    height: number = 250,
    width: number | string = window.innerWidth - 5 || 334
  ) => {
    return `https://videoplayer.betfair.com/GetPlayer.do?tr=1&eID=${eID}&width=${width}&height=${height}&allowPopup=true&contentType=viz&statsToggle=hide&contentOnly=true`;
  };

  getSoccerScoreStatus = (status: string) => {
    switch (status) {
      case 'SecondHalfKickOff':
        return 'Second Half Kick Off';
        break;
      case 'FirstHalfEnd':
        return 'First Half End';
        break;
      case 'KickOff':
        return 'Kick Off';
        break;
      case 'Finished':
        return 'Match Completed';
        break;
      default:
        return '';
    }
  };

  isSpecialEvent = (eventId): boolean => {
    if (
      [
        // SPECIAL_MATCHES.ELECTION.matchId,
        // SPECIAL_MATCHES.PUNJAB_ELECTION_2022.matchId,
        // SPECIAL_MATCHES.UP_ELECTION_2022.matchId,
        // SPECIAL_MATCHES.PSL_2022.matchId,
        // SPECIAL_MATCHES.ICCWC20.matchId,
        SPECIAL_MATCHES.IPL2022.matchId,
        // SPECIAL_MATCHES.WBBL.matchId,
        // SPECIAL_MATCHES.BBL.matchId
      ].indexOf(eventId) != -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  isSpecialMarket = (marketId: string): boolean => {
    if (
      [
        // SPECIAL_MATCHES.ICCWC20.WINNER,
        // SPECIAL_MATCHES.IPL2022.MATCH_ODDS,
        // SPECIAL_MATCHES.IPL2022.BOOKAMKER,
        SPECIAL_MATCHES.IPL2022.WINNER,
        // SPECIAL_MATCHES.WBBL.WINNER,
        // SPECIAL_MATCHES.PSL_2022.WINNER,
        // SPECIAL_MATCHES.BBL.WINNER
      ].indexOf(marketId) != -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  getTennisScoreStatus = (status: string) => {
    switch (status) {
      case 'Inprogress':
        return 'In Progress';
        break;
      case 'FirstHalfEnd':
        return 'First Half End';
        break;
      case 'KickOff':
        return 'Kick Off';
        break;
      case 'Finished':
        return 'Match Completed';
        break;
      default:
        return '';
    }
  };

  /**
   * @method getLiveTvVisibility
   * @param { number } sportId
   * @description Check if live tv should be visible
   */
  getLiveTvVisibility = (sportId: number): boolean => {
    switch (sportId) {
      case CRICKET_SPORT_ID:
        return true;
        break;
      case SOCCER_SPORT_ID:
        return false;
        break;
      case TENNIS_SPORT_ID:
        return false;
        break;
      default:
        return true;
    }
  };

  /**
   * @method getLiveTvStartedVisibility
   * @param { number } sportId
   * @description Check if live tv should be visible
   */
  getLiveTvStartedVisibility = (sportId: number, date: number): boolean => {
    return sportId == CRICKET_SPORT_ID && isOlderThanCurrent(date);
  };

  /**
   * @method isLotusScoreBoardAllowed
   * @param { number } sportId
   * @description Check if lotus score board should be visible
   */
  isLotusScoreBoardAllowed = (sportId: number, date: number): boolean => {
    return (
      [CRICKET_SPORT_ID, TENNIS_SPORT_ID].includes(sportId) &&
      isOlderThanCurrent(date)
    );
  };

  /**
   * @method getIPAddress
   * @description Gets the IP address of the user
   */
  getIPAddress = async () => {
    return await (await fetch('https://api.ipify.org/?format=json')).json();
  };

  /**
   * @method getLiveTV1URL
   * @description Gets libe tv 1 url
   */
  getLiveTV1URL = async () => {
    return await (
      await fetch(
        'https://aaaa-14afe-cc669-betfair.firebaseio.com/liveTV1.json'
      )
    ).json();
  };

  /**
   * @method createHash
   * @param { string } data
   * @description Covert string to hash
   */
  createHash = (data: string): string => {
    return btoa(data);
  };

  /**
   * @method destructureHash
   * @param { string } hash
   * @description Covert hash to string
   */
  destructureHash = (hash: string): string => {
    return atob(hash);
  };

  getPnlMarketLiability = (
    selectionId,
    marketId,
    marketLiabilities
  ): number => {
    if (!marketId || !selectionId || !marketLiabilities[marketId]) {
      return 0;
    }

    let liabilitySelections = marketLiabilities[marketId];

    if (this.isSpecialMarket(marketId)) {
      let profit = 0;
      liabilitySelections.forEach((mrktLiability) => {
        if (mrktLiability.selectionid == selectionId) {
          profit = mrktLiability.total_pnl;
        }
      });
      return profit;
    } else {
      switch (Number(selectionId)) {
        case liabilitySelections['selection1']:
          return liabilitySelections['pnl1'];
        case liabilitySelections['selection2']:
          return liabilitySelections['pnl2'];
        case liabilitySelections['selection3']:
          return liabilitySelections['pnl3'];
        default:
          return 0;
      }
    }
  };

  /**
   * @method getOvers
   * @param score
   * @param ballPlayed
   * @param totalBalls
   * @description fetch Current Run Rate
   */
  getOvers(ballsPlayed: number): string {
    return (parseInt('' + ballsPlayed / 6) + (ballsPlayed % 6) / 10).toFixed(1);
  }

  /**
   * @method getCRR
   * @param score
   * @param ballPlayed
   * @param totalBalls
   * @description fetch Current Run Rate
   */
  getCRR(score: number, ballsPlayed: number, totalBalls: number): string {
    // formula for calculating exact run rate is 6 * [Runs scored / {(No of completed overs * 6)+(No of balls bowled in the last incomplete over)}]
    if (score == 0) {
      return '0';
    }
    return (
      score /
      (parseInt('' + ballsPlayed / 6) + (ballsPlayed % 6) / 10)
    ).toFixed(2);
  }

  /**
   * @method getRR
   * @param score
   * @param reaquiredScore
   * @param ballPlayed
   * @param totalBalls
   * @description fetch Required Run Rate
   */
  getRR(
    score: number,
    reaquiredScore: number,
    ballPlayed: number,
    totalBalls: number
  ): string {
    if (score == 0) {
      return '0';
    }
    let remainingBalls: number = totalBalls - ballPlayed;
    // formula for calculating exact run rate is 6 * [Runs scored / {(No of completed overs * 6)+(No of balls bowled in the last incomplete over)}]
    return (
      (reaquiredScore - score) /
      (parseInt('' + remainingBalls / 6) + (remainingBalls % 6) / 10)
    ).toFixed(2);
  }

  getPlofitLossColor(amount: number, isCasino = false) {
    let color = '';
    if (amount < 0) {
      color = 'text-danger';
    } else {
      color = 'text-success';
    }
    return color;
  }

  isInActiveMarket = (marketStatus: string): boolean => {
    marketStatus = marketStatus.toUpperCase();

    if (marketStatus == MARKET_STATUS.SUSPENDED) {
      return true;
    } else if (marketStatus == MARKET_STATUS.CLOSED) {
      return true;
    }
    return false;
  };

  isActiveMarket = (marketStatus: string): boolean => {
    marketStatus = marketStatus.toUpperCase();

    if (marketStatus == MARKET_STATUS.OPEN) {
      return true;
    } else if (marketStatus == '') {
      return true;
    } else if (marketStatus == MARKET_STATUS.ACTIVE) {
      return true;
    }
    return false;
  };

  isDetailActiveMarket = (
    runnerStatus: string,
    marketStatus: string
  ): boolean => {
    marketStatus = marketStatus.toUpperCase();
    runnerStatus = runnerStatus.toUpperCase();

    if (marketStatus == MARKET_STATUS.SUSPENDED) {
      return false;
    }
    if (runnerStatus == MARKET_STATUS.OPEN) {
      return true;
    } else if (runnerStatus == '') {
      return true;
    } else if (runnerStatus == MARKET_STATUS.ACTIVE) {
      return true;
    }
    return false;
  };

  showMarketSuspendedMessage = (marketStatus: string): boolean => {
    marketStatus = marketStatus.toUpperCase();

    if (marketStatus == MARKET_STATUS.SUSPENDED) {
      return true;
    }
    return false;
  };

  /**
   * @method isCasinoEvent
   * @param matchId
   * @description Checks if the event is casino event
   */
  isCasinoEvent = (matchId: number) => {
    if (Number(matchId) > 500) {
      return true;
    }
    return false;
  };

  getProfitLoss = (
    currentSelectionId: string,
    marketName: string,
    profitAndLoss: UserBetProfitAndLoss
  ): ProfitLoss => {
    if (marketName != profitAndLoss.marketName) {
      return null;
    }
    let profitLoss: ProfitLoss = {
      pnl: 0,
      style: null,
    };
    // console.log(currentSelectionId)
    switch (profitAndLoss.type) {
      case ODDS_TYPE.BACK:
        if (profitAndLoss.betTeamSelectionId == currentSelectionId) {
          profitLoss.pnl = profitAndLoss.profit;
          profitLoss.style = 'text-success';
        } else {
          profitLoss.pnl = profitAndLoss.liability;
          profitLoss.style = 'text-danger';
        }
        break;
      case ODDS_TYPE.LAY:
        if (profitAndLoss.betTeamSelectionId == currentSelectionId) {
          profitLoss.pnl = profitAndLoss.liability;
          profitLoss.style = 'text-danger';
        } else {
          profitLoss.pnl = profitAndLoss.profit;
          profitLoss.style = 'text-success';
        }
        break;
      default:
        profitLoss = null;
    }

    return profitLoss;
  };

  formatCardRankDetail = (winner: string): string => {
    // let allDetails: string[] = detail.split(' ');

    // let rank = allDetails.length > 0 ? allDetails[0] : null;

    if (!winner) {
      return null;
    } else if (winner == 'L') {
      return CASINO_CARD_RANKS.LOW;
    } else if (winner == 'H') {
      return CASINO_CARD_RANKS.HIGH;
    } else {
      return CASINO_CARD_RANKS.SEVEN;
    }
  };

  isActiveCasinoMarket = (marketStatus: string): boolean => {
    try {
      const cStatus = parseInt(marketStatus);

      if (cStatus) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  isActiveDiamondCasinoMarket = (marketStatus: string): boolean => {
    try {
      if (
        marketStatus != MARKET_STATUS.SUSPENDED &&
        marketStatus != MARKET_STATUS.CLOSED
      ) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  getFormattedMarketName = (marketName: string): string => {
    switch (marketName) {
      case 'Match Odds':
        return FORMATTED_MARKET_NAMES.MATCH_ODDS;

      case 'Completed Match':
        return FORMATTED_MARKET_NAMES.COMPLETED_MATCH;

      case 'Tied Match':
        return FORMATTED_MARKET_NAMES.TIED_MATCH;

      case 'Bookmaker':
        return FORMATTED_MARKET_NAMES.BOOKMAKER;

      case 'Bookmaker3':
        return FORMATTED_MARKET_NAMES.BOOKMAKER_3;

      case 'TOSS':
        return FORMATTED_MARKET_NAMES.TOSS;

      case 'Winner':
        return FORMATTED_MARKET_NAMES.WINNER;

      default:
        return null;
    }
  };

  // CASINO

  getCasinoCardRank = (card: string): string => {
    if (!card) {
      return null;
    }
    try {
      const rank = card.split('');
      let cardVal =
        rank.length == 4 ? rank[0].toString() + rank[1].toString() : rank[0];
      switch (cardVal) {
        case 'A':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
          return CASINO_CARD_RANKS.LOW;
        case '8':
        case '9':
        case '10':
        case 'J':
        case 'Q':
        case 'K':
          return CASINO_CARD_RANKS.HIGH;
        case '7':
          return CASINO_CARD_RANKS.SEVEN;
        default:
          return null;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  getBollywoodSequence = (sequence: number): string => {
    let SEQ = ['A', 'B', 'C', 'D', 'E', 'F'];
    return SEQ[sequence];
  };

  getCasinoCardsByIndex = (index: number): number => {
    if (index && index >= 0 && index <= 13) {
      return index;
    } else if (index == 14) {
      return 1;
    } else {
      return 0;
    }
  };

  getLotusCasinoCardVisible = (
    resultRoundId: string,
    gameId: string
  ): boolean => {
    if (resultRoundId! == gameId.split('.')[1]) {
      return false;
    } else {
      return true;
    }
  };

  isActiveLotusCasinoMarket = (marketStatus: string): boolean => {
    switch (marketStatus) {
      case MARKET_STATUS.OPEN:
        return true;
      case MARKET_STATUS.SUSPENDED:
        return false;
      case MARKET_STATUS.CLOSED:
        return false;
      default:
        return false;
    }
  };

  getLotusCasinoUpdatedTimeDiff = (roundTime: number): number => {
    return parseInt(
      (new Date().getTime() / 1000 - roundTime / 1000).toString()
    );
  };

  getFormattedLotusRoundId = (roundId: string) => {
    return roundId.split('.')[0] + '.' + roundId.split('.')[1];
  };
}
