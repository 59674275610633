import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { LoaderComponent } from './loader/loader.component';
import { CommonRulesModalComponent } from './common-rules-modal/common-rules-modal.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    CommonRulesModalComponent,
    ComingSoonComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedDirectivesModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    ComingSoonComponent
  ]
})
export class SharedComponentsModule { }
