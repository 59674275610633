import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService, ActiveToast } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  options: IndividualConfig;

  constructor(
    private toastr: ToastrService
  ) {
    this.options = this.toastr.toastrConfig;
    this.options.positionClass = 'toast-bottom-right';
    this.options.timeOut = 1500;
  }


  /**
   * @method showToast
   * @param title
   * @param message
   * @param type
   * @description
   * @type success | error | info | warning
   */
  showToast(title, message, type, extraOptions = {}): ActiveToast<number> {
    return this.toastr.show(message, title, { ...this.options, ...extraOptions }, 'toast-' + type);
  }

  /**
   * @method hideSingleToast
   * @param {number} toasterId
   */
  hideSingleToast(toasterId: number) {
    this.toastr.clear(toasterId);
  }


  /**
    * @method clearAllToasts
    */
  clearAllToasts() {
    this.toastr.clear();
  }


}
