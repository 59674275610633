<div class="modal-header">
  <h4 class="modal-title">{{name}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="resetModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <ng-container>
    <div class="table-responsive">
      <table class="table b-table table-striped table-bordered">
        <thead>
          <tr role="row" class="">
            <th class="text-right">No</th>
            <th class="text-center">Nation</th>
            <th class="text-center">Event Name</th>

            <!-- <th class="text-center">Event Type</th> -->
            <!-- <th class="text-center">Bet Type</th> -->
            <th class="text-center">Market Name</th>
            <!-- <th class="text-center">Rate</th> -->
            <th class="text-right">Amount</th>
            <!-- <th class="text-center">Place Date</th> -->
          </tr>
        </thead>


        <tbody>
          <ng-container *ngIf="!userRepositoryService.isUserAllUnSettledBetsLoading; else unSettledBetsLoading">
            <ng-container *ngIf="userRepositoryService.userAllUnSettledBets | async; else unSettledNotFound">
              <tr *ngFor="let unSettledBets of userRepositoryService.userAllUnSettledBets | async; index as i;">
                <td class="text-center">{{i + 1}}</td>
                <td class="text-center">{{unSettledBets.selectionname + (unSettledBets.marketname == "Fancy"? '/'+
                  (unSettledBets.pricevalue) : '')}}</td>
                <td class="text-center">{{unSettledBets.matchname}}</td>
                <!-- <td class="text-center">{{unSettledBets.sportname}}</td> -->
                <!-- <td class="text-center">
                  <span *ngIf="unSettledBets.isback">
                    <span *ngIf="unSettledBets.marketname == 'Fancy'; else back">Yes</span>
                    <ng-template #back>
                      <span>Back (Lagai)</span>
                    </ng-template>
                  </span>
                  <span *ngIf="unSettledBets.islay">
                    <span *ngIf="unSettledBets.marketname == 'Fancy'; else lay">No</span>
                    <ng-template #lay>
                      <span>Lay (Khai)</span>
                    </ng-template>
                  </span>
                </td> -->
                <td class="text-center">{{unSettledBets.marketname}}</td>
                <!-- <td class="text-right">{{unSettledBets.odds}}</td> -->
                <td class="text-center">{{unSettledBets.stake}}</td>
                <!-- <td class="text-center">{{unSettledBets.placetime}}</td> -->
              </tr>
            </ng-container>
          </ng-container>

          <ng-template #unSettledBetsLoading>
            <tr>
              <td colspan="8">
                <div class="fancy-message col-12 mt-1">
                  <img class="common-loader" src="assets/images/loader.gif">
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template #unSettledNotFound>
            <tr>
              <td colspan="8">
                <div class="fancy-message col-12 mt-1">No real-time records found</div>
              </td>
            </tr>
          </ng-template>

        </tbody>
      </table>
    </div>
  </ng-container>

</div>
